import { navigate } from 'gatsby'
import { useState } from 'react'
import Header from '../components/Header'
import Toast from '../components/toast'
import { useMoralis } from '../hooks/moralis'
import { toast } from 'react-toastify'

import { useForm, SubmitHandler } from 'react-hook-form'
import { CleanValueIcon } from '../components/CleanValueIcon'
import { ErrorMessage } from '../components/ErrorMessage'

type LoginFormType = {
  username: string
  password: string
  isAgree: boolean
}

const Login: React.FC = () => {
  const { login, userLogin } = useMoralis()
  const [showPassword, setShowPassword] = useState(false)
  const {
    register,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
  } = useForm<LoginFormType>()
  const onSubmit: SubmitHandler<LoginFormType> = ({
    username,
    password,
    isAgree,
  }) => {
    toast.dismiss()
    if (!isAgree)
      return toast.warn('Please agree to the User Privacy Agreement first')
    userLogin?.({ username, password })
  }
  return (
    <>
      <Header />
      <Toast />
      <div className="flex w-full mt-20 font-regularText sm:mt-[57px]">
        <div className="w-[538px] mx-auto mt-[112px] mb-[170px] px-[41px] pt-[77px] pb-[133px] bg-[linear-gradient(360deg,#ffffff30_0%,#eaf4fcb5_100%)] rounded-2xl sm:m-0 sm:h-auto sm:rounded-none sm:pt-[93px] sm:px-6">
          <div className="text-[32px] leading-8 font-bold font-boldText">
            LOG IN TO CTIO
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-12 sm:mt-[28px]">
              <div className="relative">
                <input
                  {...register('username', {
                    required: 'Please enter your username.',
                  })}
                  type="username"
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] border border-solid rounded ${
                    errors.username ? 'border-red-500' : 'border-none'
                  }`}
                  placeholder="Your Username"
                />
                {watch('username') && (
                  <CleanValueIcon onClick={() => resetField('username')} />
                )}
              </div>
              {errors.username && (
                <ErrorMessage
                  message={errors.username.message}
                  className="text-sm"
                />
              )}
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('password', {
                    required: 'Please enter your password',
                  })}
                  type={showPassword ? 'text' : 'password'}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] border-solid border rounded ${
                    errors.password ? ' border-red-500 ' : 'border-none'
                  }`}
                  placeholder="Password"
                />
                {watch('password') && (
                  <CleanValueIcon
                    className="!right-10"
                    onClick={() => resetField('password')}
                  />
                )}
                {showPassword ? (
                  <svg
                    className="absolute w-4 h-4 -translate-y-1/2 right-4 top-1/2 text-[#C4C4C4] cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="absolute w-4 h-4 -translate-y-1/2 right-4 top-1/2 text-[#C4C4C4] cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                )}
              </div>
              {errors.password && (
                <ErrorMessage
                  message={errors.password.message}
                  className="text-sm"
                />
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex items-center mt-8 ml-4 sm:ml-0 sm:justify-center sm:order-last">
                <input
                  {...register('isAgree')}
                  type="checkbox"
                  className="w-[18px] h-[18px] sm:w-4 sm:h-4 ml-1"
                />
                <div className="ml-2 text-[#BDBDBD] text-[12px] leading-[18px]">
                  Agree to the{' '}
                  <a href="/login" className="text-[#1e88e5]">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a href="/login" className="text-[#1e88e5]">
                    Privacy Policy
                  </a>
                </div>
              </div>
              <button
                type="submit"
                className="bg-[linear-gradient(270deg,#1E88E5_0%,#64B5F6_100%)] rounded w-full h-[50px] text-white mt-8 shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] sm:w-full sm:mt-[72px]"
              >
                Log in
              </button>
              {/*                             <button
                type="button"
                className="bg-[linear-gradient(270deg,#1E88E5_0%,#64B5F6_100%)] rounded w-full h-[50px] text-white mt-4 shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] sm:w-full sm:mt-[72px]"
              >
                Connect With Metamask
              </button> */}
            </div>
          </form>
          <div className="flex text-[#F87575] text-sm text-center mt-[33px] sm:mt-[231px] select-none">
            <div
              className="border-r border-[#F87575] w-full cursor-pointer sm:cursor-default"
              onClick={() => navigate('/sign')}
            >
              Register Now
            </div>
            <div className="w-full cursor-pointer sm:cursor-default">
              Forgot password
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
