import classnames from 'classnames'

type ErrorMessageType = {
  message?: string
  className?: string
}
/**
 * @param {string} message?: Error Messages
 * @param {string} className?: Class Name
 * @returns
 */
export const ErrorMessage: React.FC<ErrorMessageType> = ({
  message,
  className,
}) => (
  <div
    className={classnames(
      'flex items-center text-[#F87575] mt-[10px]',
      className,
    )}
  >
    <svg
      className="w-4 h-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <div className="ml-1">{message ?? 'This field is required.'}</div>
  </div>
)
